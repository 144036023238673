<!-- <div class="dock-window">
    <ul>
        <li *ngIf="hasContributorPermission" 
        [ngClass]="{activeIcons: selectedMenu === 'contributor'}" (click)="SelectRole('contributor')" pTooltip="Contributor" >
        <img src="../../assets/layout/images/Collaborator.svg" /></li>
        <li *ngIf="hasAdminPermission" [ngClass]="{activeIcons: selectedMenu === 'admin'}" (click)="SelectRole('admin')" pTooltip="Administrator" >
        <img src="../../assets/layout/images/Admin.svg" /></li>
        <li *ngIf="hasDeveloperPermission" [ngClass]="{activeIcons: selectedMenu === 'developer'}" (click)="SelectRole('developer')" pTooltip="Developer" >

        <img src="../../assets/layout/images/Developer.svg" /></li>
        <li *ngIf="showDocumentTaskList" 
        [ngClass]="{activeIcons: selectedMenu === 'Documents'}" (click)="SelectRole('Documents')" pTooltip="Documents" >
        <img src="../../assets/layout/images/Collaborator.svg" /></li>
        
    </ul>
</div>
<ul class="layout-menu">
    <ng-container *ngFor="let item of model; let i = index;">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>

</ul> -->

<div class="dock-window">
    <ul>
        <li *ngIf="hasContributorPermission" [ngClass]="{activeIcons: selectedMenu === 'contributor'}"
            (click)="SelectRole('contributor')" pTooltip="Contributor">
            <img alt="Collaborator" src="../../assets/layout/images/Collaborator.svg" />
        </li>
        <li *ngIf="hasAdminPermission" [ngClass]="{activeIcons: selectedMenu === 'admin'}" (click)="SelectRole('admin')"
            pTooltip="Administrator">
            <img alt="admin" src="../../assets/layout/images/Admin.svg" />
        </li>
        <li *ngIf="hasDeveloperPermission" [ngClass]="{activeIcons: selectedMenu === 'developer'}"
            (click)="SelectRole('developer')" pTooltip="Developer">
            <img alt="developer" src="../../assets/layout/images/Developer.svg" />
        </li>
        <li *ngIf="showDocumentTaskList" class="documentsTab" [ngClass]="{activeIcons: selectedMenu === 'Documents'}"
            (click)="SelectRole('Documents')" pTooltip="Documents">
            <img alt="Collaborator" src="../../assets/layout/images/Collaborator.svg" />
        </li>
    </ul>
</div>

<ul class="layout-menu">
    <ng-container *ngFor="let item of model; let i = index;">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
    <li *ngIf="selectedMenu === 'Documents'">
        <button aria-label="loadMore"  class="loadMoreBtn" (click)="loadMoreDocuments()" [disabled]="(DocumentsList.length + skipCount) === documentTotalCount">
            <span>Load More  ({{DocumentsList.length}} / {{documentTotalCount}}) </span><i pTooltip="Load More" class="pi pi-arrow-circle-down"></i>
        </button>
    </li>
</ul>