import { Inject, Injectable, Injector } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Subject, map, lastValueFrom, Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from '../../../environments/environment';
import { AppConsts } from 'src/app/core/constraints/appConsts';
import { AppEndPoint } from '../../EndPointUrls/appEndPoints.enum';
import { IUser, IUserRole } from '../../interfaces/IUser';
import { BaseService } from '../../shared/services/base.service';
import * as END_POINTS from 'src/app/EndPointUrls/apiEndPoints.enum';
import { BillingStatus } from 'src/app/interfaces/billingstatus';
import { __Permissions } from '../permissionEnum/permissions';

import { catchError, filter, takeUntil, tap } from 'rxjs/operators';

import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SilentRequest, SsoSilentRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { b2cPolicies } from 'src/app/auth-config';
import { LoaderService } from './loader.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { parse } from 'path';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
    acr?: string,
    tfp?: string,
};



@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService {
    subscribtions?: Subscription;
    public loggedInUser = {} as IUser;
    userID: any
    tenantId: any;
    // userPermissions:string[]=[];
    selectedUserRole = {} as IUserRole
    isIframe = false;
    loginDisplay = false;
    userDataAvailable: boolean = false;
    private readonly _destroying$ = new Subject<void>();
    queryparams: any = null;
    URL: string = '';
    showPermissionContent: boolean = false;
    public loadApplication: boolean = true;
    constructor(private injector: Injector, public router: Router,
        public loader: LoaderService,
        private route: ActivatedRoute,
        private cookieService: CookieService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        public toastr: ToastrService,
        private msalBroadcastService: MsalBroadcastService) {

        super(injector);
        // this.oAuthService.configure(OIDC_Config);
    }

    // setup_AND_Configure_OIDC() {
    //     this.store_RequestedUrl();
    //     this.subscribe_OIDC_Events();
    //     if (this.oAuthService.hasValidAccessToken()) {
    //         this.getUserInfo_and_navigate();
    //     }
    //     else {
    //         this.oAuthService.loadDiscoveryDocumentAndLogin().then((res) => {
    //             if (res && this.oAuthService.hasValidAccessToken())
    //                 this.getUserInfo_and_navigate();
    //         });
    //     }
    // }
    unsubscribe_OIDC__Events() {
        this.subscribtions?.unsubscribe();
    }
    // subscribe_OIDC_Events() {
    //     this.subscribtions = this.oAuthService.events.subscribe((event) => {
    //         if (event.type === 'token_error' || event.type == 'token_validation_error') {
    //             localStorage.clear();
    //             this.oAuthService.logOut();
    //         }
    //         if (event.type === 'invalid_nonce_in_state') {
    //             this.oAuthService.initCodeFlow();
    //         }
    //         if (event.type === 'token_expires') {
    //             this.oAuthService
    //                 .refreshToken()
    //                 .then()
    //                 .catch((err) => { });
    //         }
    //     });
    // }
    public store_RequestedUrl() {
        if (!sessionStorage.getItem("requested_url")) {
            sessionStorage.setItem("requested_url", window.location.href.toString());
        }
    }
    public navigate_to_requested_url(loggedIn = false) {
        if (window.location.href.startsWith(`${environment.AUTH_REDIRECT_URI}/logout`)) {
            window.location.href = window.location.href;
          } else {
            this.router.navigate(['']);
          }
    }

    loadConfig(config: any) {
        AppConsts.EMRandEMHLink = config.EMRandEMHLink;
        AppConsts.allowedFiles = config.allowedFiles;
        AppConsts.contactUsLink = config.contactUsLink;
        AppConsts.homeVideoUrl = config.homeVideoUrl;
        AppConsts.waitingScreenVideoUrl = config.waitingScreenVideoUrl;
    }

    setConfig() {
        return this.http.get<any>('assets/configs/' + environment.appConfig).pipe(map((config) => {
            this.loadConfig(config);
        }));
    }
    public getUserInfo_and_navigate() {
        this.getUserInfo().subscribe(res => {
            // let user=res[0] as IUser;
            let config = res as any;
            this.loadConfig(config);
            this.navigate_to_requested_url();
        });
    }
    getTaskCount() {
        return this.http.get(`${this.doc_api}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetTaskCount}`)
    }
    getUserPermissionsAndBillingStatus() {
        if (this.loggedInUser.activeTenantKey != undefined) {
            var billingStatus = this.http.get(`${this.cloudapiUrl}${END_POINTS.USER_Subscription.Subscriptions}/${this.loggedInUser.activeTenantKey}`);
            var permissions = this.http.get<any>(`${this.doc_api.split('/api/services/app')[0]}${END_POINTS.ABP_USERConfiguration.AbpUserConfiguration}${END_POINTS.ABP_USERConfiguration_EndPoints.GetAll}`)

            return forkJoin([permissions, billingStatus]).pipe(
                catchError(error => {
                    // handle error
                    if (error.status === 401 || error.status === 406) {
                        this.loader.show()
                        this.refreshToken();
                    }
                    throw error;
                })

            );
        } else {

            this.router.navigate([AppEndPoint.BlockOrganization])
            this.loadApp(true);
        }

    }
    set_UserPermissions_And_BillingStatus(reload = false) {
        this.getUserPermissionsAndBillingStatus().subscribe((res: any[]) => {
            //permissions
            this.userID = res[0].result.session.userId;
            this.tenantId = res[0].result.session.tenantId;
            let permissions = res[0].result?.auth?.grantedPermissions as any;
            if (permissions) {
                this.loggedInUser.userPermissions = []
                Object.keys(permissions).forEach(p => {
                    this.loggedInUser.userPermissions.push(p);
                });
            }
            //billing status
            if (res[1] != undefined) {
                let subsStatus: BillingStatus = (res[1].organizationSubscriptions as BillingStatus[]).find(x => ((x.status.toLowerCase() === 'active' || x.status.toLowerCase() == 'trialing' || x.status.toLowerCase() == 'paused') && (x.productName == 'OpenBots Cloud - Documents' || x.productName == 'OpenBots Document Assistant')));

                if (subsStatus != undefined) {
                    this.loggedInUser.bill = subsStatus;
                    this.router.navigate([AppEndPoint.Home]);
                }
                else {
                    this.loggedInUser.bill = {
                        status: 'Blocked'
                    } as BillingStatus;
                    this.loggedInUser.bill.status = 'Blocked';
                    this.showPermissionContent = false;
                    this.router.navigate([AppEndPoint.BlockOrganization]);
                }
            }
            this.loadApp(reload);
        });
    }
    public getUserInfo() {
        return this.http.get<IUser>(`${this.cloudapiUrl}${END_POINTS.AUTH.Auth}${END_POINTS.AUTH_EndPoints.GetUserInfo}`);
        //      .pipe(map(async (user:IUser)=>{
        //         this.loggedInUser=user;
        //         this.loggedInUser.userPermissions=[];
        //         this.loggedInUser.activeTenantKey=this.loggedInUser.myOrganizations[0].tenantKey;
        //    this.set_UserPermissions_And_BillingStatus();
        //     }));
        //    var config= this.http.get<any>( 'assets/configs/'+environment.appConfig);
        //    return forkJoin([userinfo,config]);
    }


    refreshToken(): Observable<any> {
        const signInAccount = this.msalService.instance.getActiveAccount();
        var request: SilentRequest = {
            scopes: environment.Azure_AD_B2C.Scope,
            account: signInAccount,
            forceRefresh: true,
        };
        return this.msalService.acquireTokenSilent(request)
    }

    hasPermission(permission: string, checkBillingStatus: boolean = true) {
        if (checkBillingStatus == false) {
            if (this.loggedInUser.userPermissions.find(p => p === permission)) {
                return true;
            } else {
                return false
            }
        }
        else {
            if (this.loggedInUser.userPermissions.find(p => p === permission) != undefined && (this.loggedInUser.bill.status.toLowerCase() === 'paused' || this.loggedInUser.bill.status.toLowerCase() === 'active' || this.loggedInUser.bill.status.toLowerCase() === 'trialing')) {
                return true;
            }
            else {
                return false;
            }
        }
        // return false;
    }
    getTenantId(tenantKEY: string) {
        let keys: string[] = [];
        keys.push(tenantKEY);
        return this.http.post<any>(`${this.doc_api}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.GetTenantIdsByKey}`, keys)
    }
    getUserPermissions() {
        return this.http.get<any>(`${this.doc_api}${END_POINTS.ABP_USERConfiguration.AbpUserConfiguration}${END_POINTS.ABP_USERConfiguration_EndPoints.GetAll}`)
    }

    getAuthToken() {
        // return this.oAuthService.getAccessToken();
    }
    logout() {
        let obApps = environment.Azure_AD_B2C.baseState.split(',');

        const logoutHint = localStorage.getItem('id_token');

        localStorage.clear();
        sessionStorage.clear();
        
        const activeAccount =
          this.msalService.instance.getActiveAccount() ||
          this.msalService.instance.getAllAccounts()[0];
    
        this.msalService.logoutRedirect({
          account: activeAccount,
          idTokenHint: logoutHint,
          postLogoutRedirectUri: `${environment.Azure_AD_B2C[obApps[0]]}logout`,
          state: obApps.join(',')
        });

    }

    loadApp(reload: boolean) {
        // we need this settimeout logic
        // for reloading side menu and permission load 
        if (reload == true) {
            this.userDataAvailable = false;
            setTimeout(() => {
                this.userDataAvailable = true;
            }, 100);
        }
        else {
            this.userDataAvailable = true;
        }
    }

    getUserClaims() {

        const claimsObj = JSON.parse(localStorage.getItem('id_token_claims_obj'));
        this.loggedInUser.email = claimsObj['emails'][0] as string
        this.loggedInUser.personId = claimsObj['extension_identity_person_id'];
        this.loggedInUser.isUserConsentRequired = claimsObj['extension_identity_user_consent'] as boolean;
        this.loggedInUser.personName = claimsObj['name'] as string

        const organizationsClaims = claimsObj['extension_Organizations'] as string;
        const organizationPieces = organizationsClaims.split(",");


        this.loggedInUser.myOrganizations = [];
        if (organizationPieces && organizationPieces.length > 0) {
            for (const org of organizationPieces) {
                const orgInfo = org.split('~');
                this.loggedInUser.myOrganizations.push({
                    name: orgInfo[0],
                    tenantKey: orgInfo[1],
                    id: orgInfo[2],
                    isAdministrator: false
                })
            }
        }

        this.loggedInUser.userPermissions = [];


        // set selected organization from galaxy
        // let url = sessionStorage.getItem("requested_url")?.replace(environment.AUTH_REDIRECT_URI, "");
        
        const url = new URL(sessionStorage.getItem("requested_url"));
        const params = new URLSearchParams(url.search);
        
        let saveOrgId = '';

        if (params.has("saveOrgId")) {
            
            if(params.get("saveOrgId").includes('%2526')) {
                saveOrgId = params.get("saveOrgId").split('%2526')[0]
            } else if(params.get("saveOrgId").includes('&')) { 
                saveOrgId = params.get("saveOrgId").split('&')[0]
            } else {
                saveOrgId = params.get("saveOrgId")
            }
        } else {
            if (localStorage.getItem('organization_tenant_key') != null) {
                saveOrgId = localStorage.getItem('organization_tenant_key');
            } else {
                saveOrgId = this.loggedInUser.myOrganizations[0].tenantKey;
            }
        }
        let orgFound = false;
        for (let tKey of this.loggedInUser.myOrganizations) {
            if (tKey.tenantKey == saveOrgId) {
                orgFound = true;
                localStorage.setItem('saveOrgId', tKey.tenantKey);
                localStorage.setItem('organization_tenant_key', tKey.tenantKey);
                localStorage.setItem('organizationId', tKey.id);
                localStorage.setItem('orgname', tKey.name);
                this.loggedInUser.activeTenantKey = tKey.tenantKey;
                // break;
            } else {
                orgFound = false;
            }
        }
    }



    async getUserPermission() {
        // token claims user info
        const claimsObj = JSON.parse(localStorage.getItem('id_token_claims_obj'));
        const self = this;

        if (!claimsObj.hasOwnProperty('extension_Organizations')) {
            // refresh token

            return new Promise(async (resolve, reject) => {
                const signInAccount = this.msalService.instance.getAllAccounts();

                let signUpSignInFlowRequest: SsoSilentRequest = {
                    authority: b2cPolicies.authorities.signUpSignIn.authority,
                    account: signInAccount[0]
                };
                // silently login again with the signUpSignIn policy
                lastValueFrom(this.msalService.ssoSilent(signUpSignInFlowRequest)).then((async data => {

                    let authResult = data;

                    localStorage.setItem('id_token', authResult.idToken);
                    localStorage.setItem('id_token_claims_obj', JSON.stringify(authResult.idTokenClaims));

                    this.getUserClaims();
                    this.getClaimsUserPermission()
                        .then(() => {
                            resolve(true)
                        })
                }));
            });

        } else {
            this.getUserClaims();
            await this.getClaimsUserPermission();

        }
    }

    async getClaimsUserPermission(tokenRefresh = false) {
        let billing_permissions$ = this.getUserPermissionsAndBillingStatus();
        await lastValueFrom(billing_permissions$).then((res: any) => {

            this.userID = res[0].result.session.userId;
            this.tenantId = res[0].result.session.tenantId;
            let permissions = res[0].result?.auth?.grantedPermissions as any;
            if (permissions) {
                Object.keys(permissions).forEach(p => {
                    this.loggedInUser.userPermissions.push(p);
                });
            }

            //billing status
            if (res[1] != undefined) {

                if (res[1].organizationSubscriptions.length > 0) {
                    let subsStatus: BillingStatus = (res[1].organizationSubscriptions as BillingStatus[]).find(x => ((x.status.toLowerCase() === 'active' || x.status.toLowerCase() == 'trialing' || x.status.toLowerCase() == 'paused') && (x.productName == 'OpenBots Cloud - Documents' || x.productName == 'OpenBots Document Assistant')));
                    if (subsStatus != undefined) {

                        this.loggedInUser.bill = subsStatus;

                        if (tokenRefresh) {
                            this.loadUserInformation();
                            this.router.navigate(['']);
                        }
                    }
                    else {
                        this.loggedInUser.bill = {
                            status: 'Blocked'
                        } as BillingStatus;
                        this.showPermissionContent = false;

                        this.router.navigate([AppEndPoint.BlockOrganization]);
                    }
                } else {
                    this.loggedInUser.bill = {
                        status: 'Blocked'
                    } as BillingStatus;
                    this.showPermissionContent = false;

                    this.router.navigate([AppEndPoint.BlockOrganization]);
                }
            } else {
                this.showPermissionContent = false;
                this.loggedInUser.bill = {
                    status: 'Blocked'
                } as BillingStatus;
                this.router.navigate([AppEndPoint.BlockOrganization]);
            }

            this.setConfig();
        });
    }

    async getUserInformation() {
        const user$ = this.getUserInfo();
        await lastValueFrom(user$).then(async (res: any) => {
            let user = res as IUser;
            this.loggedInUser = {} as IUser;
            this.loggedInUser = user;
            this.loggedInUser.userPermissions = [];
            if (localStorage.getItem("organization_tenant_key") == "" || localStorage.getItem("organization_tenant_key") == null) {
                this.loggedInUser.activeTenantKey = this.loggedInUser.myOrganizations[0].tenantKey;
                localStorage.setItem("organization_tenant_key", this.loggedInUser.myOrganizations[0].tenantKey);
            }
            else {
                this.loggedInUser.activeTenantKey = localStorage.getItem("organization_tenant_key");
            }
            let billing_permissions$ = this.getUserPermissionsAndBillingStatus();
            await lastValueFrom(billing_permissions$).then((res: any) => {
                // this.userID = res
                this.userID = res[0].result.session.userId;
                this.tenantId = res[0].result.session.tenantId;
                let permissions = res[0].result?.auth?.grantedPermissions as any;
                if (permissions) {
                    Object.keys(permissions).forEach(p => {
                        this.loggedInUser.userPermissions.push(p);
                    });
                }



                //billing status
                if (res[1] != undefined) {

                    if (res[1].organizationSubscriptions.length > 0) {
                        let subsStatus: BillingStatus = (res[1].organizationSubscriptions as BillingStatus[]).find(x => ((x.status.toLowerCase() === 'active' || x.status.toLowerCase() == 'trialing' || x.status.toLowerCase() == 'paused') && (x.productName == 'OpenBots Cloud - Documents' || x.productName == 'OpenBots Document Assistant')));
                        if (subsStatus != undefined) {
                            this.loggedInUser.bill = subsStatus;
                        }
                        else {
                            this.loggedInUser.bill = {
                                status: 'Blocked'
                            } as BillingStatus;
                            this.showPermissionContent = false;

                            // this.loggedInUser.bill.status = 'Blocked';
                            this.router.navigate([AppEndPoint.BlockOrganization]);
                        }
                    }
                } else {
                    this.showPermissionContent = false;
                    this.loggedInUser.bill = {
                        status: 'Blocked'
                    } as BillingStatus;
                    this.router.navigate([AppEndPoint.BlockOrganization]);
                }

                this.setConfig();
            });
        });
    }

    handleAzureB2CLogin() {
        this.store_RequestedUrl();

        this.isIframe = window !== window.parent && !window.opener;
        this.URL = window.location.href;
        const accounts = this.msalService.instance.getAllAccounts();


        //check user is completely logged out before login
        // when click logout button and quickly close tab or browser 
        // the app breaks with loader showing without any error
        const queryReturnIsAuth = this.GetParam('isAuthenticated');
        const queryReturnReturn = this.GetParam('returnurl');

        if (queryReturnIsAuth == 'true') {
            if (this.URL.includes('login'))
                window.location.href = window.location.href.replace('/login', '')
        }
        if (accounts.length == 0 && queryReturnIsAuth == 'true' && !this.URL.includes('signin-oidc') && !this.URL.includes('signin-ms-oidc')) {
            this.login();
        } else if (accounts.length == 0 && queryReturnIsAuth != 'true') {
            if (!this.URL.includes('signin-oidc') && !this.URL.includes('signin-ms-oidc')) {
                // window.location.href = `${environment.Azure_AD_B2C.GalaxyLoginUrl}?returnurl=${window.location.href}`;
                this.login();
            } else {
                this.navigate_to_requested_url()
            }
        }

        if (accounts.length > 0) {
            this.navigate_to_requested_url(true)
        }

        /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
            )
            .subscribe((result: EventMessage) => {
                if (this.msalService.instance.getAllAccounts().length === 0) {
                    window.location.pathname = "/";
                } else {
                    this.setLoginDisplay();
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            })

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
                    || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                    || msg.eventType === EventType.SSO_SILENT_SUCCESS),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => { 
                let payload = result.payload as AuthenticationResult;
                
                localStorage.setItem('id_token', payload.idToken);

                localStorage.setItem('id_token_claims_obj', JSON.stringify(payload.idTokenClaims));
                let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

                if (idtoken.acr === b2cPolicies.names.signUpSignIn || idtoken.tfp === b2cPolicies.names.signUpSignIn) {
                    this.msalService.instance.setActiveAccount(payload.account);
                }
                // Todo:
                // Refresh the page after setting token for first time
                // needs Refactoring don't need to refresh the page when
                // coming from galaxy first time after login
                location.reload();

                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
                 * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
                 * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (idtoken.acr === b2cPolicies.names.editProfile || idtoken.tfp === b2cPolicies.names.editProfile) {

                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = this.msalService.instance.getAllAccounts()
                        .find((account: AccountInfo) =>
                            account.idTokenClaims?.oid === idtoken.oid
                            && account.idTokenClaims?.sub === idtoken.sub
                            && ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === b2cPolicies.names.signUpSignIn
                                || (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === b2cPolicies.names.signUpSignIn)
                        );

                    let signUpSignInFlowRequest: SsoSilentRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount
                    };

                    // silently login again with the signUpSignIn policy
                    this.msalService.ssoSilent(signUpSignInFlowRequest);
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow (see above ln. 74-92).
                 */
                if (idtoken.acr === b2cPolicies.names.resetPassword || idtoken.tfp === b2cPolicies.names.resetPassword) {
                    let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
                        scopes: []
                    };

                    this.login(signUpSignInFlowRequest);
                }

                return result;
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                // Checking for the forgot password error. Learn more about B2C error codes at
                // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
                if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
                    let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
                        authority: b2cPolicies.authorities.resetPassword.authority,
                        scopes: [],
                    };

                    this.login(resetPasswordFlowRequest);
                };
            });
    }

    setLoginDisplay() {
        this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
    }

    GetParam(name: any) {
        const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (!results) {
            return 0;
        }
        return results[1] || 0;
    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            let accounts = this.msalService.instance.getAllAccounts();
            // add your code for handling multiple accounts here
            this.msalService.instance.setActiveAccount(accounts[0]);
        }
    }

    login(userFlowRequest?: RedirectRequest | PopupRequest) {      
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.msalService.instance.setActiveAccount(response.account);
                    });
            } else {
                this.msalService.loginPopup(userFlowRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.msalService.instance.setActiveAccount(response.account);
                    });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
            } else {
                this.msalService.loginRedirect(userFlowRequest);
            }
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    loadUserInformation() {
        return new Promise(async (resolve, reject) => {
            await this.getUserPermission().then(res => {

                let url = sessionStorage.getItem("requested_url")?.replace(environment.AUTH_REDIRECT_URI, "");

                sessionStorage.removeItem("requested_url");


                if (this.hasPermission(__Permissions.documentList) === true) {
                    this.getTaskCount().subscribe(
                        (data: any) => {

                            this.loadApp(true);

                            if (url == "/" || url == "") {
                                if (data?.result > 0) {
                                    this.router.navigate([AppEndPoint.Documents]);
                                } else {
                                    this.router.navigate([AppEndPoint.Home]);
                                }
                            } else {
                                if (url) {
                                    if (url.includes('signin-oidc')) {
                                        if (data?.result > 0) {
                                            this.router.navigate([AppEndPoint.Documents]);
                                        } else {
                                            this.router.navigate([AppEndPoint.Home]);
                                        }
                                    }
                                    else if (url.includes('saveOrgId')) {
                                        const orgIdPiece = url.split('saveOrgId=');
                                        let saveOrgId = ''
                                        if (orgIdPiece) {
                                            const orgId = orgIdPiece[1];
                                            saveOrgId = orgId;
                                        }

                                        const orgIdParam = {
                                            queryParams: {
                                                saveOrgId: saveOrgId,
                                            },
                                        }

                                        if (data?.result > 0) {
                                            this.router.navigate([AppEndPoint.Documents], orgIdParam);
                                        } else {
                                            this.router.navigate([AppEndPoint.Home], orgIdParam);
                                        }
                                    }
                                    else {
                                        this.router.navigateByUrl(url);
                                    }
                                }
                            }

                            resolve(res);
                        })
                }
                else {
                    this.router.navigate([AppEndPoint.BlockOrganization]);
                    if (this.loggedInUser.bill.status === 'Blocked') {
                        this.showPermissionContent = false;
                    } else {
                        this.showPermissionContent = true;
                    }
                    this.loadApp(true);
                    resolve(res);
                }
            });
        });

    }
}


