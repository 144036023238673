<ng-content select=".Tablefilter"></ng-content>
<p-table id="{{uuid}}" [class]="settings.class!==undefined?settings.class:''"
  class="riskListTable evidenceTable alertTable tablecolor" #dt [columns]="settings.columns"
  [rowsPerPageOptions]="[10,20,50]" [totalRecords]="settings.totalRecords" [value]="settings.data"
  [lazy]="settings.lazy!==undefined?settings.lazy:true" (onLazyLoad)="fetchTableData($event)" [paginator]="false"
  [rows]="10" (onRowReorder)="onRowReorderChanged($event)">
  [reorderableColumns]="!!settings.dragable"
  <ng-template class="tablecolor" pTemplate="header" let-columns>
    <tr [ngClass]="{'firstRow': settings.showDefaultFilters===false}" [pReorderableRow]>

      <ng-container *ngIf="settings?.dragable===true">
        <ng-container>
          <th style="width:3rem"></th>
        </ng-container>

      </ng-container>
      <ng-container *ngIf="settings?.selectable">
        <ng-container *ngIf="settings?.selectableType && settings?.selectableType==='radio'">
          <!-- RADIO -->
          <th>Select</th>
        </ng-container>
        <ng-container *ngIf="settings?.selectableType && settings?.selectableType==='checkbox'">
          <th>
            <p-checkbox [(ngModel)]="selectAll" [pTooltip]="this.selectAllTooltip"
              (onChange)="onselectAll($event.checked)" [binary]="true" inputId="binary"></p-checkbox>
            <i pTooltip="Invert Select" (click)="invertSelect()" class="pi pi-arrow-right-arrow-left invertSelect"></i>
          </th>
        </ng-container>
      </ng-container>
      <th *ngIf="settings.menuButton">Menu</th>
      <th [pSortableColumnDisabled]="col.sortable!==undefined && col.sortable===false" [pSortableColumn]="col.field"
        *ngFor="let col of columns">
        {{col.header}}
        <p-sortIcon *ngIf="col.sortable===undefined || col.sortable===true" [field]="col.field"></p-sortIcon>
      </th>
      <th *ngIf="settings.actions"> Actions</th>
    </tr>
    <tr>

      <!-- // check for  radio and check -->
      <th *ngIf="(settings.selectable) && settings.showDefaultFilters===true"></th>
      <th *ngIf="(settings.menuButton) && settings.showDefaultFilters===true"></th>
      <ng-container *ngIf="settings.showDefaultFilters!==undefined?settings.showDefaultFilters:false">
        <th *ngFor="let col of settings.columns; let i=index">
          <ng-container *ngIf="col.type === 'text'">
            <label for="{{i}}"
              style="line-height: 0;color: transparent !important;visibility:visible !important;width: 0px;text-indent: -9999px;">{{i}}</label>
            <p-columnFilter class="tableRowInput" [field]="col.field" matchMode="equals" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <input type="text" (keyup.enter)="applyFilter(col.defaultValue,col.field)" pInputText
                  [(ngModel)]="col.defaultValue" id="{{i}}" class="p-inputtext">
              </ng-template>
            </p-columnFilter>
          </ng-container>
          <ng-container *ngIf="col.type === 'number'">
            <label for="{{i}}"
              style="line-height: 0;color: transparent !important;visibility:visible !important;width: 0px;text-indent: -9999px;">{{i}}</label>
            <p-columnFilter class="tableRowInput" [field]="col.field" matchMode="equals" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <input (keyup.enter)="applyFilter(col.defaultValue,col.field)" type="number" pInputText
                  [(ngModel)]="col.defaultValue" id="{{i}}" class="p-inputtext">
              </ng-template>
            </p-columnFilter>
          </ng-container>
          <ng-container *ngIf="col.type === 'dropdown'">
            <p-columnFilter [field]="col.field" matchMode="equals" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <label for="{{i}}"
                  style="line-height: 0;color: transparent !important;visibility:visible !important;width: 0px;text-indent: -9999px;">{{i}}</label>
                <p-dropdown ariaFilterLabel="{{i}}" inputId="{{i}}"
                  [tooltipPosition]="col.showFiltertooltip!==undefined ? 'top' : undefined"
                  [pTooltip]="col.defaultValue" class="filter-dropdown" [(ngModel)]="col.defaultValue"
                  [options]="col.options" (onChange)="applyFilter(col.defaultValue,col.field)" placeholder="Any"
                  [showClear]="true">

                  <ng-template pTemplate="filter" let-options="options">
                    <div class="flex p-3">
                      <div class="p-inputgroup" (click)="$event.stopPropagation()">
                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                        <input type="text" pInputText placeholder="Filter" [(ngModel)]="col.defaultValue"
                          (keyup)="options.filter($event)">
                      </div>
                      <button pButton icon="pi pi-times" class="ml-3"></button>
                    </div>
                  </ng-template>
                  <ng-template let-option pTemplate="item">
                    <span [class]="'customer-badge status-' + option.value">
                      {{option}}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </ng-container>
          <ng-container *ngIf="col.type==='date'">
            <p-columnFilter [field]="col.field" matchMode="equals">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <label for="{{i}}"
                  style="line-height: 0;color: transparent !important;visibility:visible !important;width: 0px;text-indent: -9999px;">{{i}}</label>
                <p-calendar [(ngModel)]="col.defaultValue" yearRange="2000:2030" [monthNavigator]="true"
                  [yearNavigator]="true" [showButtonBar]="true" (onSelect)="dt .filter($event,col.field,'equals')"
                  styleClass="p-column-filter" [readonlyInput]="true" (onClearClick)="onclearDate(col.field)"
                  dateFormat="M-d-yy">
                </p-calendar>
              </ng-template>
            </p-columnFilter>
          </ng-container>
        </th>
      </ng-container>
      <!-- <th *ngIf="settings.actions"></th> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-i="rowIndex" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData" [pReorderableRow]="i">

      <!-- radio || checkbox -->

      <ng-container *ngIf="settings?.selectable===true">
        <td *ngIf="settings?.selectableType==='checkbox'">
          <p-checkbox [(ngModel)]="rowData.selected" (onChange)="onCheckboxSelect($event.checked,rowData)"
            [binary]="true" inputId="binary"></p-checkbox>
        </td>
        <td *ngIf="settings?.selectableType==='radio'">
          <p-radioButton [inputId]="i" name="radio_btn" [value]="rowData"
            [(ngModel)]="this.selectedRows[0]"></p-radioButton>
        </td>
      </ng-container>
      <ng-container *ngIf="settings?.dragable===true">
        <td>
          <span class="pi pi-bars" pReorderableRowHandle></span>
        </td>
      </ng-container>
      <td [id]="'menuButtontd-'+i+uuid" *ngIf="settings.menuButton" class="btn-td menuButton_td">
        <button [class]="settings.menuButton.class!==undefined?settings.menuButton.class:''"
          (blur)="hidemenu('menu-ul-'+i+uuid)" [id]="'menubutton-'+i+uuid" (click)="showMenu('menu-ul-'+i+uuid)"
          type="button" icon="pi pi-angle-down">
          <i class="pi pi-angle-down"></i>
          {{settings.menuButton.buttonLabel}}
        </button>
        <ul style="display: none;" [id]="'menu-ul-'+i+uuid" class="list-dropdwon">
          <li [id]="'menuBtn-li-'+i+uuid" style="cursor: pointer;" *ngFor="let op of settings.menuButton.options"
            (click)="menuClick(op,rowData)" [ngClass]="op.class!==undefined?op.class:''"
            [class]="rowData['menuOptionClass']!==undefined?rowData['menuOptionClass']:''">
            <i [class]="op.icon"></i>{{op.label}}
          </li>
        </ul>
      </td>
      <td *ngFor="let col of columns" [ngClass]="col.class!==undefined?col.class:''"
        [class]="rowData['dataClass']!==undefined?rowData['dataClass']:''">
        <ng-container *ngIf="col.isLink===undefined   && col.type!=='template'">
          <ob-ng-tooltip [isDate]="col.isDate" [pipe]="col.pipe" [pipeArgs]="col.pipeArgs" [data]="rowData[col.field]"
            [size]="col.tooltipSize"></ob-ng-tooltip>
        </ng-container>
        <ng-container *ngIf="col.isLink!==undefined   && col.type!=='template'">
          <span style="cursor: pointer;" (click)="linkClick(col,rowData)">
            <ob-ng-tooltip [isDate]="col.isDate" [pipe]="col.pipe" [pipeArgs]="col.pipeArgs" [data]="rowData[col.field]"
              [size]="col.tooltipSize"></ob-ng-tooltip>
          </span>
        </ng-container>
        <ng-container *ngIf="col.isLink===undefined  && col.type==='template'" [ngTemplateOutlet]="col.template"
          [ngTemplateOutletContext]="{rowData:rowData}">

        </ng-container>
      </td>
      <!--  [ngClass]="rowData['actionClass']!==undefined?rowData['actionClass']:''" -->
      <ng-container *ngIf="settings.actions!==undefined">
        <td style="text-align: center;">
          <button *ngFor="let action of settings.actions;let i=index" aria-label="test"
            [ngClass]="action.class!==undefined?action.class:''"
            [class]="rowData['actionClass']!==undefined?rowData['actionClass']:''" (click)="actionClick(action,rowData)"
            class="p-button-rounded p-button-text btnTransp">
            <i [pTooltip]="action.tooltip" id="{{i}}" style="font-size: 2rem" [class]="action.icon"
              style="font-size: 1.2rem"></i>
          </button>
        </td>
      </ng-container>
    </tr>
  </ng-template>
</p-table>
<div style="text-align: center;" *ngIf="settings?.totalRecords=== 0">
  No Record Found
</div>
<div class=" primeng-paging-container flex align-items-center justify-content-center">
  <div>
    <span class="total-records-count">
      Total: {{settings.totalRecords}}
    </span>
  </div>
  <div class="flex-1">
    <p-paginator id="page" #paginator [first]="dt._first" (onPageChange)="OnPageinatorChange($event)"
      [rows]="settings.rows ||10" [totalRecords]="settings.totalRecords"
      [rowsPerPageOptions]="settings.rowsPerPageOptions || [10,20,30]">
    </p-paginator>
  </div>
</div>