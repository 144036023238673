
export const environment:any = {
  production: false,
  AUTH_ISSUER: 'https://cloud.openbots-dev.io',
  // AUTH_REDIRECT_URI: 'http://localhost:4400',
  AUTH_REDIRECT_URI: 'https://documentai.openbots-dev.io',
  AUTH_POST_LOGOUT_URI: 'https://documentai.openbots-dev.io',
  API_URL: 'https://cloudserver-api.openbots-dev.io/',
  API_Document:'https://documents-api.openbots-dev.io/api/services/app',
  API_Document_Url: 'https://documents-api.openbots-dev.io/',
  openBotsFunctionApi:'https://openbots-documents-functions-api-dev.azurewebsites.net/api/v1/Run/',
  VERSION_URL: 'api/v4/',
  hub: 'notification',
  App_Insight_Key: '7a089d83-dc8c-4683-8e66-f7293173c246',
  isDebug: true,
  functionsApiUrl: 'https://functions-api.openbots-dev.io/api/v1/',
  hubUrl: '/notification',
  healthUrl: '/healthcheck-ui',
  Feature_Workflow: true,
  Feature_BusinessRules: true,
  BusinessApps_URL: 'https://businessapps.openbots-dev.io',
  Feature_PDF_FORMS: true,
  Feature_Site: true,
  appConfig: 'appconfig.json',
  buyCredits: `https://dev.account.openbots.io/MySubscription?Product=OPENBOTS.CLOUDSERVER`,
  SubscriptionLink: `https://account.openbots.io/Buy/OpenBots.CloudServer`,
  OPENBOTS_SITES_URL: `openbots-dev.site`,
  Feature_Schema: true,
  previewShow: true,
  isDev: true,
  documentLink: 'https://documents.openbots-dev.io',
  cloud_dashbord_link: 'https://cloud.openbots-dev.io/Members/Dashboard',
  contactUsLink:'https://openbots.ai/contact' ,
  supportlink:'https://cloud.openbots-dev.io/Support/Requests',
  studioLink:'https://gallery.openbots-dev.io/product/STUDIO',
  CommandsURL:"https://gallery.openbots-dev.io/packagedetail/OpenBots.Commands.Documents",
  vaultsUrl: "https://cloudserver.openbots-dev.io/pages/vault",
  appInsights: {
      instrumentationKey: '7ef01ed1-2955-4949-9883-936b7a8f735c',
  },
  RefillUrl: "https://openbots.ai/Refill-Documents",
  Azure_AD_B2C: {
      ClientId: '5b8a43b5-f1ef-4172-90a6-a7974542eaa4',
      Instance: "https://authenticate.openbots-dev.io/",
      GalaxyLoginUrl: "https://cloud.openbots-dev.io/Login",
      Domain: "linkgalaxydevtoazure.onmicrosoft.com",
      TenantId: "bf6dbb13-06da-40c1-b388-faf1922152d7",
      SignUpSignInPolicyId: "B2C_1_SignUpSignInDev",
      EditProfilePolicyId: "B2C_1_EditProfileDev",
      ResetPasswordPolicyId: "B2C_1_PasswordResetDev",
      ROPCPolicyId: "B2C_1_ROPC_AuthDev",
      GalaxyClientId: "2d7f9beb-a870-4f6c-9fea-363dec17c417",
      ClientSecret: "K6g8Q~xjF.PJhpVFd75ks3Ii9m~YV7no-SQUcddi",
      CallbackPath: "/signin-oidc",
      SignedOutCallbackPath: "/signout-callback-oidc",
      Scope: ['openid', 'email', 'profile', 'https://linkgalaxydevtoazure.onmicrosoft.com/adb2c/task.write', 'offline_access'],
      // cs: 'http://localhost:4300/',
      // da: 'http://localhost:4400/',
      // bc: 'http://localhost:4500/',
      // ba: 'http://localhost:4200/',
      customerAi: 'https://customerai.openbots-dev.io',
      // apps dev logout urls base
      ca: 'https://customerai.openbots-dev.io/',
      cs: 'https://cloudserver.openbots-dev.io/',
      da: 'https://documentai.openbots-dev.io/',
      bc: 'https://businesscenter.openbots-dev.io/',
      ba: 'https://businessapps.openbots-dev.io/',
      baseState: 'cs,bc,ba'
  }
}


