// import { OAuthService } from 'angular-oauth2-oidc';
import { Component, Inject } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-log-out.ts',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.scss']
})
export class LogOutComponent {
  obApps: any[] = [];
  constructor(
    private msalService: MsalService,    
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) { }

  ngOnInit(){
    
    const url = window.location.href;
    if (url.includes('state')) {
      const urlPiece = url.split('?state=');
      const apps = urlPiece[1];
      if (apps != undefined) {
        this.obApps = apps.split(',');
      }
    } else {
      this.obApps = environment.Azure_AD_B2C.baseState.split(',');
    }


    //get query param
    //remove the the app from apps query after logout and then append remaining apps to postLogout URl

    const dAppIndex = this.obApps.indexOf('da');

    if (dAppIndex != -1) {
      this.obApps.splice(dAppIndex, 1);
    }

  }

  public ngAfterViewInit(): void {
    const activeAccount =
      this.msalService.instance.getActiveAccount() ||
      this.msalService.instance.getAllAccounts()[0];

    // Extract login hint to use as logout hint

    const logoutHint = localStorage.getItem('id_token');
    this.clearAppData();

    if (logoutHint == null && this.obApps.length == 0) {
    //redirect to galaxy logout or customer ai as last application
    //   window.location.href = `${environment.AUTH_ISSUER}/Logout`;
    window.location.href = `${environment.Azure_AD_B2C.customerAi}/logout`;
    } else if (logoutHint == null) {
      //redirect to Next app logout
      window.location.href = `${environment.Azure_AD_B2C[this.obApps[0]]}logout?state=${this.obApps.join(',')}`
    } else {
    
      if(this.obApps.length > 0) {
        this.msalService.instance.initialize().then(() => {
            this.msalService.logoutRedirect({
              account: activeAccount,
              idTokenHint: logoutHint,
              postLogoutRedirectUri: `${environment.Azure_AD_B2C[this.obApps[0]]}logout`,
              state: `${this.obApps.join(',')}`,
              // state: 'da,cs,ba'
            });
          })
    } else {
        window.location.href = `${environment.Azure_AD_B2C.customerAi}/logout`;
    }
    }
  }

  private clearAppData(): void {
    sessionStorage.clear();
    localStorage.clear();
  }
}
